






























































































































































import { defineComponent } from '@vue/composition-api'
import 'vue-file-agent/dist/vue-file-agent.css'

export default defineComponent({
  name: 'upload',
  components: {},
  props: {
    isMutiple: {
      required: false,
      type: Boolean,
    },
    label: {
      required: false,
      type: String,
    },
  },

  setup() {
    return {}
  },
})
