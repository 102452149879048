






















































































































































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import RelevesIndexTable from '@/components/commune/components/RelevesIndexTable.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import CheckboxSelector from '@/components/commune/components/filter/CheckboxSelector.vue'
import {
  AdresseEan,
  authorizationTypes,
  ExportEan,
  GenericObject,
} from '@/inc/types'
import panelAccordion from '@/components/ui/PanelAccordion.vue'
import BlockTips from '@/components/ui/BlockTips.vue'
import CustomPagination from '@/components/commune/components/filter/CustomPagination.vue'
import { removeAccents } from '@/inc/utils/utils'
import * as check from '@/composables/commune'
import Upload from '@/components/Upload.vue'
import auth from '@/composables/auth'
import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from '@/inc/app.config'
import 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

const { VUE_APP_DOMAIN } = process.env
const fileToUpload = [] as Array<any>

export default defineComponent({
  name: 'commune-index',
  components: {
    Breadcrumb,
    Header,
    RelevesIndexTable,
    CustomLink,
    CheckboxSelector,
    panelAccordion,
    BlockTips,
    CustomPagination,
    Upload,
    FeedbackMessage,
  },
  watch: {
    fileRecords() {
      fileToUpload.splice(0)
      let file = []
      for (file of this.fileRecords) {
        if (!file?.['error']['size'] && !file?.['error']['type']) {
          const reader = new FileReader()
          reader.readAsText(file?.['file'])
          fileToUpload.push(reader)
        }
      }
    },
  },
  setup(props, ctx) {
    const { i18n } = useState('commune', ['i18n'])
    const { authorizations } = useGetters('user', ['authorizations'])
    const { FETCH_EANS } = useActions('commune', ['FETCH_EANS'])
    const breadcrumbPage = [
      {
        label: i18n.value.communeIndex.title?.replace(/<[^>]*>/g, ''),
        url: '',
      },
    ]
    const search: Ref<string> = ref('')
    const isLoading: Ref<boolean> = ref(false)
    const dataList: Ref<AdresseEan[]> = ref([])
    let eans: string[] = []
    let compteurs: string[] = []
    let rues: string[] = []
    let energies: string[] = []
    const items: Ref<AdresseEan[]> = ref(dataList.value)
    const paginatedItems: Ref<AdresseEan[]> = ref([])
    const activePanels: Ref<number[]> = ref([])
    const sortAdrOrder: Ref<string> = ref('ASC')
    const sortEanOrder: Ref<string> = ref('')
    const pageSize = 10
    const currentPage: Ref<number> = ref(1)
    const exportDatas: Ref<ExportEan[]> = ref([])
    const { currentLang } = useGetters(['currentLang'])
    let releveIndexPath = 'ma-situation/mon-index/releve-index/'
    if (currentLang.value === 'de') {
      releveIndexPath = 'mein-situation/mein-zahlerstand/meine-zahlerablesung/'
    }
    const releveIndexUrl = ref(
      `${VUE_APP_DOMAIN}/${currentLang.value}/${releveIndexPath}`
    )

    const typesFluides: GenericObject[] = []
    const indexUrl = ref('')
    const disableBtnSubmit = ref(true)
    const feedbackMessage = ref('')
    const hasError = ref(false)
    const submiting = ref(false)
    const fileRecords = ref([])
    const fileRecordsForUpload = ref([])
    const formUpload = false

    const deleteUploadedFile = () => {
      fileRecords.value = []
      fileRecordsForUpload.value = []
    }
    const filesSelected = fileRecordsNewlySelected => {
      feedbackMessage.value = ''
      const validFileRecords = fileRecordsNewlySelected.filter(
        fileRecord => !fileRecord.error
      )
      fileRecordsForUpload.value =
        fileRecordsForUpload.value.concat(validFileRecords)
      if (validFileRecords.length === 0) {
        disableBtnSubmit.value = true
      } else {
        disableBtnSubmit.value = false
      }
    }
    const onBeforeDelete = (fileRecord: never) => {
      const i = fileRecordsForUpload.value.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        fileRecordsForUpload.value.splice(i, 1)
        const k = fileRecords.value.indexOf(fileRecord)
        if (k !== -1) {
          fileRecords.value.splice(k, 1)
        }
        // eslint-disable-next-line no-alert
      } else if (confirm('Are you sure you want to delete?')) {
        fileRecords.value = []
        fileRecordsForUpload.value = []
      }
    }
    const fileDeleted = (fileRecord: never) => {
      const i = fileRecordsForUpload.value.indexOf(fileRecord)
      // eslint-disable-next-line no-negated-condition
      if (i !== -1) {
        fileRecordsForUpload.value.splice(i, 1)
      } else {
        deleteUploadedFile()
      }
    }

    const sortItemsByOrder = (field: string | null = 'adresse') => {
      if (field === 'adresse') {
        items.value.sort((a, b) => {
          if (a.adresse.rue < b.adresse.rue) {
            return sortAdrOrder.value === 'DESC' ? 1 : -1
          }
          if (a.adresse.rue > b.adresse.rue) {
            return sortAdrOrder.value === 'DESC' ? -1 : 1
          }

          return 0
        })
      } else if (field === 'ean') {
        items.value.sort((a, b) => {
          if (a.eans.length < b.eans.length) {
            return sortEanOrder.value === 'DESC' ? 1 : -1
          }
          if (a.eans.length > b.eans.length) {
            return sortEanOrder.value === 'DESC' ? -1 : 1
          }

          return 0
        })
      }

      pageChanged({ currentPage: 1 })
    }

    const onSubmitDownload = async () => {
      let res: AxiosResponse | null = null
      const headers: Record<string, unknown> = {}
      const config = {} as Record<string, any>
      const method = 'get'
      try {
        res = await axios[method](`${getApiUrl()}/town/index_download`, config)
        indexUrl.value = res?.data
        let csvContent = 'data:text/csv;charset=utf-8,'
        csvContent += indexUrl.value
        const encodedUri = encodeURI(csvContent)
        const fileLink = document.createElement('a')
        fileLink.href = encodedUri
        fileLink.setAttribute('download', 'index.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
      } catch (err) {
        console.log(err)
      }
    }

    const onSubmitUpload = async () => {
      const headers: Record<string, unknown> = {}
      const config = {} as Record<string, any>
      const method = 'post'
      const data = {
        body: fileToUpload[0]?.['result'],
      }
      disableBtnSubmit.value = true
      await axios
        .post(`${getApiUrl()}/town/index_upload`, data)
        .then(response => {
          if (response.status === 204) {
            submiting.value = false
            hasError.value = false
            feedbackMessage.value = i18n.value.upload.success.ok
            deleteUploadedFile()
          }
        })
        .catch(e => {
          if (
            e.response.data.originalBody.ErrorCode === 'WRONG_HEADERS' ||
            e.response.data.originalBody.ErrorCode === 'WRONG_NUMBER_COL'
          ) {
            feedbackMessage.value = e.response.data.message
          } else {
            feedbackMessage.value = e.response.data.message
          }
          disableBtnSubmit.value = false
          submiting.value = false
          hasError.value = true
        })
    }

    onBeforeMount(async () => {
      isLoading.value = true
      dataList.value = check.getRelevesIndexData()
      if (!dataList.value || dataList.value.length === 0) {
        await FETCH_EANS()
        dataList.value = check.getRelevesIndexData()
      }
      exportDatas.value = []
      dataList.value.forEach(item => {
        const key = item.eans[0].ean
        typesFluides[key] = []
        item.eans.forEach(ean => {
          if (eans.indexOf(ean.ean) === -1) {
            eans.push(ean.ean)
          }
          if (compteurs.indexOf(ean.numCpt) === -1) {
            compteurs.push(ean.numCpt)
          }
          if (energies.indexOf(ean.type) === -1) {
            energies.push(ean.type)
          }
          exportDatas.value.push({
            rue: item.adresse.rue,
            numero: item.adresse.numRue,
            cp: item.adresse.codePostal,
            localite: item.adresse.localite,
            ean: `'${ean.ean}'`,
            compteur: `'${ean.numCpt}'`,
            energie: ean.type,
          })

          if (typesFluides[key].indexOf(ean.type) === -1) {
            typesFluides[key].push(ean.type)
          }
        })
        if (rues.indexOf(item.adresse.rue) === -1) {
          rues.push(item.adresse.rue)
        }
      })

      exportDatas.value.sort((a, b) => {
        if (a.rue < b.rue) {
          return -1
        } else if (a.rue > b.rue) {
          return 1
        }

        return 0
      })
      eans.sort()
      compteurs.sort()
      energies.sort()
      rues.sort()
      items.value = dataList.value
      sortItemsByOrder()
      isLoading.value = false
    })

    const columns = [
      {
        label: i18n.value.relevesIndex.colonne.ean,
        field: 'ean',
        type: 'string',
        thClass: 'ean',
        tdClass: 'ean',
      },
      {
        label: i18n.value.relevesIndex.colonne.compteur,
        field: 'numCpt',
        type: 'string',
        thClass: 'compteur',
        tdClass: 'compteur',
      },
      {
        label: i18n.value.relevesIndex.colonne.energie,
        field: 'type',
        type: 'string',
        thClass: 'energie',
        tdClass: 'energie',
      },
      {
        label: i18n.value.relevesIndex.colonne.date,
        field: 'date',
        type: 'string',
        thClass: 'date',
        tdClass: 'date',
      },
      {
        label: i18n.value.relevesIndex.colonne.releves,
        field: 'releves',
        type: 'string',
        thClass: 'releves',
        tdClass: 'releves',
      },
      {
        label: '',
        field: 'btn',
        html: true,
        globalSearchDisabled: true,
        sortable: false,
        thClass: 'actions',
        tdClass: 'actions',
      },
    ]

    // Recupere le emit pour filtrer via les eans
    ctx.root.$on('checkbox:selector:eans', data => {
      eans = data
      filterGlobal()
    })
    // Recupere le emit pour filtrer via les compteurs
    ctx.root.$on('checkbox:selector:compteurs', data => {
      compteurs = data
      filterGlobal()
    })
    // Recupere le emit pour filtrer via les rues
    ctx.root.$on('checkbox:selector:rues', data => {
      rues = data
      filterGlobal()
    })
    // Recupere le emit pour filtrer via les energies
    ctx.root.$on('checkbox:selector:energies', data => {
      energies = data
      filterGlobal()
    })

    const pageChanged = page => {
      currentPage.value = page.currentPage
      paginatedItems.value = []
      paginatedItems.value.push(...items.value)
      let start = (page.currentPage - 1) * pageSize
      if (start >= paginatedItems.value.length || pageSize <= -1) {
        start = 0
        currentPage.value = 1
      }
      const end = start + pageSize
      paginatedItems.value = paginatedItems.value.slice(start, end)
    }

    // List filters
    const filterGlobal = () => {
      items.value = dataList.value
      activePanels.value = []

      // if any search or filter active
      if (
        eans.length > 0 ||
        compteurs.length > 0 ||
        rues.length > 0 ||
        energies.length > 0 ||
        search.value
      ) {
        // check all possible filters
        if (eans.length > 0) {
          items.value = items.value.filter(item =>
            item.eans.some(ean => eans.includes(ean.ean))
          )
        }
        if (compteurs.length > 0) {
          items.value = items.value.filter(item =>
            item.eans.some(ean => compteurs.includes(ean.numCpt))
          )
        }
        if (rues.length > 0) {
          items.value = items.value.filter(item =>
            rues.includes(item.adresse.rue)
          )
        }
        if (energies.length > 0) {
          items.value = items.value.filter(item =>
            item.eans.some(ean => energies.includes(ean.type))
          )
        }

        // if fulltext search
        if (items.value.length > 0 && search.value) {
          items.value = items.value.filter(
            item =>
              item.eans.some(ean =>
                ean.ean.toLowerCase().includes(search.value.toLowerCase())
              ) ||
              item.eans.some(ean =>
                ean.numCpt.toLowerCase().includes(search.value.toLowerCase())
              ) ||
              removeAccents(item.adresse.rue)
                .toLowerCase()
                .includes(removeAccents(search.value).toLowerCase()) ||
              item.adresse.codePostal
                .toString()
                .includes(search.value.toLowerCase()) ||
              removeAccents(item.adresse.localite)
                .toLowerCase()
                .includes(removeAccents(search.value).toLowerCase()) ||
              item.eans.some(ean =>
                removeAccents(ean.type)
                  .toLowerCase()
                  .includes(removeAccents(search.value).toLowerCase())
              )
          )
        }
      }

      // keep sort order
      sortItemsByOrder(sortEanOrder.value === '' ? 'adresse' : 'ean')
      // reset pagination
      pageChanged({ currentPage: 1 })
    }

    const togglePanel = (panel: number) => {
      if (activePanels.value.indexOf(panel) >= 0) {
        activePanels.value.splice(activePanels.value.indexOf(panel), 1)
        activePanels.value = [] // Pour ne garder que un panel ouvert à la fois
      } else {
        activePanels.value = [] // Pour ne garder que un panel ouvert à la fois
        activePanels.value.push(panel)
      }
    }

    const doSearch = () => {
      filterGlobal()
    }

    const sortBy = (field: string) => {
      activePanels.value = []
      if (field === 'adresse') {
        sortEanOrder.value = ''
        if (sortAdrOrder.value === 'ASC') {
          sortAdrOrder.value = 'DESC'
        } else if (sortAdrOrder.value === 'DESC') {
          sortAdrOrder.value = 'ASC'
        } else {
          sortAdrOrder.value = 'ASC'
        }
      } else if (field === 'ean') {
        sortAdrOrder.value = ''
        if (sortEanOrder.value === 'ASC') {
          sortEanOrder.value = 'DESC'
        } else if (sortEanOrder.value === 'DESC') {
          sortEanOrder.value = 'ASC'
        } else {
          sortEanOrder.value = 'ASC'
        }
      }

      sortItemsByOrder(field)
    }

    // To reset the toggled panels when current page change
    watch(currentPage, () => {
      activePanels.value = []
    })

    return {
      isLoading,
      i18n,
      breadcrumbPage,
      columns,
      search,
      items,
      paginatedItems,
      authorizationTypes,
      authorizations,
      togglePanel,
      activePanels,
      eans,
      compteurs,
      rues,
      energies,
      doSearch,
      sortBy,
      sortAdrOrder,
      sortEanOrder,
      pageChanged,
      pageSize,
      currentPage,
      exportDatas,
      releveIndexUrl,
      typesFluides,
      check,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
      onSubmitDownload,
      onSubmitUpload,
      indexUrl,
      disableBtnSubmit,
      feedbackMessage,
      hasError,
      submiting,
      fileRecords,
      fileRecordsForUpload,
      formUpload,
      filesSelected,
      onBeforeDelete,
    }
  },
})
